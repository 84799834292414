import { useParams } from "react-router-dom";
import { Program } from "../../components/program/Program";
import { Container } from "react-bootstrap";

export function ProgramPage(props) {
  const { id } = useParams();
  const { isEdit, isExport, isNew } = props;
  return (
    <Container style={{ maxWidth: "75%" }}>
      <Program
        id={id}
        isEdit={isEdit}
        isExport={isExport}
        isNew={isNew}
      />
    </Container>
  );
}
