import { Button, Table, Container } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useMemo, useState } from "react";
import { dbApi } from "../../services/firebase";

function Programs(props) {
  const { programs } = props;
  const [sortBy, setSortBy] = useState(() => sortByTitle);
  //const allSessionsPromise = useMemo(() => dbApi.getAllSessions(), []);
  //const [allSessions, setAllSessions] = useState([]);
  //const allPresenterEmailsAndIdsArrayPromise = useMemo(() => dbApi.getAllPresenterEmailsAndIds(), []);
  //const [allPresenterEmailsAndIdsArray, setAllPresenterEmailsAndIdsArray] = useState([]);

  /*useEffect(() => {
    allPresenterEmailsAndIdsArrayPromise.then((emailArray) => {
      setAllPresenterEmailsAndIdsArray(emailArray);
    });
  }, [allPresenterEmailsAndIdsArrayPromise]);*/

  /*useEffect(() => {
    allSessionsPromise.then((sessions) => {
      setAllSessions(sessions);
    });
  }, [allSessionsPromise]);*/

  //// Get the presenters emails via sessionId's from a specific program via its cells data
  async function downloadEmailsOfPresentersInProgram(programId, programTitle) {
    try {
      const cells = await dbApi.getCellsOfProgram(programId);
      const allSessions = await dbApi.getAllSessions(); // Assuming a function to fetch all sessions
      const allPresenterEmailsAndIdsArray = await dbApi.getAllPresenterEmailsAndIds(); // Assuming a function to fetch all presenter emails and IDs

      const sessionIds = cells.map((cell) => (cell.sessionRef ? cell.sessionRef.id : null));

      const confirmedPresenterEmailsSet = new Set();
      const confirmedPresenterData = ["First Name,Last Name,Email"];

      sessionIds.forEach((sessionId) => {
        const session = allSessions.find((session) => session.id === sessionId);

        if (session) {
          session.presenters.forEach((presenter) => {
            const presenterInfo = allPresenterEmailsAndIdsArray.find((info) => info?.presenter === presenter);

            if (presenterInfo && !confirmedPresenterEmailsSet.has(presenterInfo.email)) {
              const { firstName, lastName, email } = presenterInfo;
              const presenterData = `${firstName},${lastName},${email}`;
              confirmedPresenterData.push(presenterData);
              confirmedPresenterEmailsSet.add(email);
            }
          });
        }
      });

      const csvContent = confirmedPresenterData.join("\n");
      const blob = new Blob([csvContent], { type: "text/csv" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = `${programTitle}_email_list.csv`;
      link.click();
    } catch (error) {
      console.error("Error fetching program cells:", error.message);
    }
  }

  return (
    <>
      <h1 className={"sessionListTitle"}>Programs</h1>
      <div className={"sessionListSubTitle"}>
        List contains <b>{programs?.length}</b> presenters
      </div>
      <div className={"table-responsive-sm"}>
        <Table
          bordered
          striped
          className={"sessionTable"}
        >
          <thead>
            <tr>
              <th
                className={"hoverEffect"}
                style={{ width: "80%" }}
                onClick={() => (sortBy === sortByTitle ? setSortBy(() => sortByTitleReversed) : setSortBy(() => sortByTitle))}
              >
                Title {sortBy === sortByTitle ? <> &#8595;</> : ""} {sortBy === sortByTitleReversed ? <> &#8593;</> : ""}
              </th>
              <th
                className={"hoverEffect"}
                style={{ width: "10%" }}
                onClick={() => (sortBy === sortByCreated ? setSortBy(() => sortByCreatedReversed) : setSortBy(() => sortByCreated))}
              >
                Created {sortBy === sortByCreated ? <> &#8595;</> : ""} {sortBy === sortByCreatedReversed ? <> &#8593;</> : ""}
              </th>
              <th
                className={"hoverEffect"}
                style={{ width: "10%" }}
                onClick={() => (sortBy === sortByModified ? setSortBy(() => sortByModifiedReversed) : setSortBy(() => sortByModified))}
              >
                Modified {sortBy === sortByModified ? <> &#8595;</> : ""} {sortBy === sortByModifiedReversed ? <> &#8593;</> : ""}
              </th>
            </tr>
          </thead>
          <tbody>
            {programs.sort(sortBy).map((program, index) => (
              <tr key={index}>
                <td>
                  <Link to={"/programs/view/" + program.id}>{program.title}</Link>
                  <Button
                    onClick={() => downloadEmailsOfPresentersInProgram(program.id, program.title)}
                    style={{ marginTop: "0em", float: "right" }}
                    className={"btn btn-sm"}
                    variant="outline-primary"
                  >
                    get presenter emails
                  </Button>
                </td>
                <td>{formatDate(new Date(program.created?.seconds * 1000))}</td>
                <td>{formatDate(new Date(program.modified?.seconds * 1000))}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </>
  );
}

export function ProgramsPage() {
  const [programs, setPrograms] = useState();
  useMemo(() => dbApi.getAllPrograms().then((promisedPrograms) => setPrograms(promisedPrograms)), []);

  const navigate = useNavigate();
  return (
    <Container style={{ maxWidth: "75%" }}>
      <h1>Programs</h1>
      <div>
        <div style={{ marginBottom: "1em" }}>You can create different flavours of a program and then decide which one is the best.</div>
        <div>One measure of a good program is that everyone who has voted can attend many of their selected sessions. The more the better.</div>
        <div>
          A person's <b>Program Attendance Factor (PAF)</b> indicates how many preferred sessions a person can attend. To be able to attend, the session must be in the program AND it can't be scheduled at the same time as another preferred session. Thus, the PAF is a number between 0 (none of my sessions on the program) and 8 (all my sessions on the program and scheduled in non-overlapping slots).
        </div>
        <div>A Program's Program Attendance Factor is the average over all the voters.</div>
      </div>
      {programs && <Programs programs={programs} />}
      <Button
        onClick={() => redirectToCreateNewProgram(navigate)}
        style={{ marginTop: "2em" }}
        className={"btn button-standard"}
      >
        Create new program
      </Button>
    </Container>
  );
}

function redirectToCreateNewProgram(navigate) {
  navigate("/programs/create");
}

function formatDate(date) {
  const day = date.getDate().toString();
  const month = (date.getMonth() + 1).toString();
  const year = date.getFullYear().toString();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  return day + "/" + month + "/" + year + " " + hours + ":" + (minutes < 10 ? "0" + minutes : minutes);
}

function sortByTitle(a, b, reversed) {
  let value = 0;
  if (reversed) value = 2;
  if (!a) return 1 - value;
  if (!b) return -1 + value;
  if (a.title.toUpperCase() < b.title.toUpperCase()) return -1 + value;
  if (a.title.toUpperCase() > b.title.toUpperCase()) return 1 - value;
  return 0;
}

function sortByTitleReversed(a, b) {
  return sortByTitle(a, b, true);
}

function sortByCreated(a, b, reversed) {
  let value = 0;
  if (reversed) value = 2;
  if (!a) return 1 - value;
  if (!b) return -1 + value;
  if (a.created < b.created) return -1 + value;
  if (a.created > b.created) return 1 - value;
  return 0;
}

function sortByCreatedReversed(a, b) {
  return sortByCreated(a, b, true);
}

function sortByModified(a, b, reversed) {
  let value = 0;
  if (reversed) value = 2;
  if (!a) return 1 - value;
  if (!b) return -1 + value;
  if (a.modified < b.modified) return -1 + value;
  if (a.modified > b.modified) return 1 - value;
  return 0;
}

function sortByModifiedReversed(a, b) {
  return sortByModified(a, b, true);
}
