import { SuccessDialog } from "../../components/SuccessDialog";

export function CreateSessionConfirmationPage() {
  return (
    <SuccessDialog
      title={"Create Session"}
      text={
        "An email has been sent to the given email address(es). Follow the instruction in the email to make your session active."
      }
    />
  );
}
