import { DisplayCell, SessionCell, TextCell } from "./Cell";
import { CellSelector } from "./CellSelector";

export function SessionsMatrix(props) {
  const { matrix, setMatrix, sessions, filteredSessions, setFilteredSessions, isEdit, isExport } = props;
  return (
    <>
      {matrix.map((row, columnIndex) => {
        return row.map((matrixCell, rowIndex) => {
          if (matrixCell) {
            if (matrixCell.type === "session")
              return (
                <SessionCell
                  session={matrixCell.object}
                  coordinates={matrixCell.coordinates}
                  matrix={matrix}
                  setMatrix={setMatrix}
                  isEdit={isEdit}
                  isExport={isExport}
                  key={`session-${columnIndex}-${rowIndex}`}
                />
              );
            if (matrixCell.type === "text")
              return (
                <TextCell
                  text={matrixCell.object}
                  coordinates={matrixCell.coordinates}
                  matrix={matrix}
                  setMatrix={setMatrix}
                  isEdit={isEdit}
                  key={`text-${columnIndex}-${rowIndex}`}
                />
              );
          } else if (isEdit)
            return (
              <DisplayCell
                key={`display-${columnIndex}-${rowIndex}`}
                coordinates={{ x: columnIndex + 2, y: rowIndex + 2 }}
              >
                <CellSelector
                  sessions={sessions}
                  filteredSessions={filteredSessions}
                  setFilteredSessions={setFilteredSessions}
                  matrix={matrix}
                  setMatrix={setMatrix}
                  coordinates={{ x: columnIndex, y: rowIndex }}
                />
              </DisplayCell>
            );
          return <></>;
        });
      })}
    </>
  );
}
