import { SessionRowFromList } from "../../components/SessionRowFromList";
import { useEffect, useMemo, useState } from "react";
import { dbApi } from "../../services/firebase";
import { useAuthContext } from "../../contexts/authContext";
import Dropdown from "react-bootstrap/Dropdown";
import { Container, Table } from "react-bootstrap";
import { SessionsPrintPage } from "./SessionsPrintPage";
import ReactDOM from "react-dom";
import { useLayoutEffect } from "react";

const SortBy = {
  Title: "title",
  Presenters: "presenters",
  Reviews: "reviews",
  Votes: "votes",
  Topic: "topic",
  Created: "created",
  Modified: "modified",
};

const Arrangement = {
  Ascending: true,
  Descending: false,
};

export default function SessionsPage() {
  const [sessions, setSessions] = useState(null);
  const [cancelledSessions, setCancelledSessions] = useState(null);
  const [sortedSessions, setSortedSessions] = useState(null);
  const [sortedCancelledSessions, setSortedCancelledSessions] = useState(null);
  const [loadedSortFromStorage, setLoadedSortFromStorage] = useState(false);
  const [reviews, setReviews] = useState(null);
  const [votes, setVotes] = useState(null);
  const [comments, setComments] = useState(null);
  const [sessionsToPrint, setSessionsToPrint] = useState([]);
  const [topicToPrint, setTopicToPrint] = useState("");
  const sessionsPromise = useMemo(() => dbApi.getAllSessions(), []);
  const presentersPromise = useMemo(() => dbApi.getAllPresenters(), []);
  const reviewsPromise = useMemo(() => dbApi.getAllReviews(), []);
  const votesPromise = useMemo(() => dbApi.getVotes(), []);
  const commentsPromise = useMemo(() => dbApi.getAllComments(), []);
  const { user, currentPresenter } = useAuthContext();
  const stateReviewPromise = useMemo(() => dbApi.getReviewsAndCommentsState(), []);
  const [stateReview, setStateReview] = useState(false);
  const stateXpFactorPromise = useMemo(() => dbApi.getXpFactorActiveState(), []);
  const [stateXpFactor, setStateXpFactor] = useState(false);

  useEffect(() => {
    stateXpFactorPromise.then((promisedState) => setStateXpFactor(promisedState));
  }, [stateXpFactorPromise]);

  // Initialize sessions and insert associated presenters into them
  useEffect(() => {
    Promise.all([sessionsPromise, presentersPromise]).then(([promisedSessions, promisedPresenters]) => {
      console.log("Fetched sessions:", promisedSessions);
      console.log("Fetched presenters:", promisedPresenters);
      if (!promisedSessions || !promisedPresenters) {
        setSessions([]);
        return;
      }

      const promisedSessionsWithResolvedPresenters = promisedSessions.map((promisedSession) => {
        if (!promisedSession.presenters) {
          promisedSession.presenters = [];
          return promisedSession;
        }

        promisedSession.presenters = promisedSession.presenters.map((presenterOfSession) => {
          const resolvedPresenter = promisedPresenters.find((promisedPresenter) => presenterOfSession === promisedPresenter.id);

          return resolvedPresenter || "unassigned";
        });

        return promisedSession;
      });

      setSessions(promisedSessionsWithResolvedPresenters);
    });
  }, [presentersPromise, sessionsPromise]);

  const exportSessions = async (sessions, reviews, votes, comments) => {
    try {
      const sessionData = [];
      if (stateXpFactor) sessionData.push("Title,Subtitle,Short Description,Description,Presenter,Second Presenter,XP Factor,Total Votes,Total Reviews,Comments,Content,Created,Experience Level,Material URL,Modified,Ref Number,Session Type,State,Topic,Verified,Duration,Intended Audience,Laptops Required,Material Description,Materials Needed,Max Participants,Other Limitations,Outline or Timetable,Room Setup,Session Goal,Session Type");
      if (!stateXpFactor) sessionData.push("Title,Subtitle,Short Description,Description,Presenter,Second Presenter,Total Votes,Total Reviews,Comments,Content,Created,Experience Level,Material URL,Modified,Ref Number,Session Type,State,Topic,Verified,Duration,Intended Audience,Laptops Required,Material Description,Materials Needed,Max Participants,Other Limitations,Outline or Timetable,Room Setup,Session Goal,Session Type");
      sessions.forEach((session) => {
        const title = session.title || "";
        const subtitle = session.subtitle || "";
        const shortdesc = sanitizeContent(session.shortdesc || "");
        const desc = sanitizeContent(session.content || "");
        const presenter = session?.presenters[0] ? `${session?.presenters[0].firstName} ${session?.presenters[0].lastName}` : "";
        const secondPresenter = session?.presenters[1] ? `${session?.presenters[1].firstName} ${session?.presenters[1].lastName}` : "";
        const xpFactor = session.xpFactor || "";
        const totalVotes = votes.filter((vote) => vote.sessionID === session.id).length;
        const totalReviews = reviews.filter((review) => review.sessionDocID === session.id).length;
        const totalComments = comments.filter((comment) => comment.sessionId === session.id).length;
        const content = session.content || "";
        const created = formatDate(session.created) || "";
        const experienceLevel = session.experienceLevel || "";
        const materialUrl = session.materialUrl || "";
        const modified = formatDate(session.modified) || "";
        const refNumber = session.refNumber || "";
        const sessionType = session.sessionType || "";
        const state = session.state || "";
        const topic = session.topic || "";
        const verified = session.verified || "";
        const duration = session.duration || "";
        const intendedAudience = session.intendedAudience || "";
        const laptopsRequired = session.laptopsRequired || "";
        const materialDescription = session.materialDescription || "";
        const materialsNeeded = session.materialsNeeded || "";
        const maxParticipants = session.maxParticipants || "";
        const otherLimitations = session.otherLimitations || "";
        const outlineOrTimetable = session.outlineOrTimetable || "";
        const roomSetup = session.roomSetup || "";
        const sessionGoal = session.sessionGoal || "";

        if (stateXpFactor) {
          let formattedSession = [title, subtitle, shortdesc, desc, presenter, secondPresenter, xpFactor, totalVotes, totalReviews, totalComments, content, created, experienceLevel, materialUrl, modified, refNumber, sessionType, state, topic, verified, duration, intendedAudience, laptopsRequired, materialDescription, materialsNeeded, maxParticipants, otherLimitations, outlineOrTimetable, roomSetup, sessionGoal, sessionType].map((field) => `"${String(field).replace(/"/g, '""')}"`).join(",");
          sessionData.push(formattedSession);
        } else {
          let formattedSession = [title, subtitle, shortdesc, desc, presenter, secondPresenter, totalVotes, totalReviews, totalComments, content, created, experienceLevel, materialUrl, modified, refNumber, sessionType, state, topic, verified, duration, intendedAudience, laptopsRequired, materialDescription, materialsNeeded, maxParticipants, otherLimitations, outlineOrTimetable, roomSetup, sessionGoal, sessionType].map((field) => `"${String(field).replace(/"/g, '""')}"`).join(",");
          sessionData.push(formattedSession);
        }
      });

      // Create CSV file

      const csvText = sessionData.join("\n");

      const blob = new Blob([csvText], { type: "text/csv" });

      const a = document.createElement("a");
      a.href = URL.createObjectURL(blob);
      a.download = "session_data.csv";
      a.style.display = "none";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } catch (error) {
      console.error("Error exporting session data:", error.message);
    }
  };
  function formatDate(timestamp) {
    if (!timestamp || !timestamp.seconds) return "";
    const date = new Date(timestamp.seconds * 1000);
    return date.toLocaleString();
  }
  function sanitizeContent(content) {
    return content.replace(/\n/g, " ");
  }

  const handleExportClick = () => {
    exportSessions(sessions, reviews, votes, comments);
  };

  // Load reviews
  useEffect(() => {
    reviewsPromise.then((promisedReviews) => {
      setReviews(promisedReviews);
    });
  }, [reviewsPromise]);

  // Load votes
  useEffect(() => {
    votesPromise.then((promisedVotes) => {
      setVotes(promisedVotes);
    });
  }, [votesPromise]);

  useEffect(() => {
    commentsPromise.then((promisedComments) => {
      setComments(promisedComments);
    });
  }, [commentsPromise]);

  // Add total reviews and votes to sessions
  useEffect(() => {
    if (!sessions || !sessions.length > 0 || !reviews || !votes) return;
    if (sortedSessions || sortedCancelledSessions) return;

    // Add reviews to sessions
    const sessionsWithReviews = sessions.map((session) => {
      session.totalReviews = reviews.filter((review) => review.sessionDocID === session.id).length;
      return session;
    });

    // Add votes to sessions
    let sessionsWithReviewsAndVotes = sessionsWithReviews.map((session) => {
      session.totalVotes = votes.filter((vote) => vote.sessionID === session.id).length;
      return session;
    });

    let sessionsWithReviewsAndVotesAndComments = sessionsWithReviewsAndVotes.map((session) => {
      session.totalComments = comments.filter((comment) => comment.sessionID === session.id).length;
      return session;
    });

    setSessions(sessionsWithReviewsAndVotesAndComments);

    // Also set cancelled sessions
    const cancelledSessions = sessionsWithReviewsAndVotes.filter((session) => session.state === "Cancelled");
    setCancelledSessions(cancelledSessions);

    // Filter out cancelled sessions
    sessionsWithReviewsAndVotes = sessionsWithReviewsAndVotes.filter((session) => session.state !== "Cancelled");

    // Initial sorting
    sort(sessionsWithReviewsAndVotes, SortBy.Created, Arrangement.Descending, setSortedSessions, setSortCondition);
    sortCancelledSessions(cancelledSessions, SortBy.Created, Arrangement.Descending, setSortedCancelledSessions, setSortConditionForCancelled);
  }, [sessions, reviews, votes, sortedSessions, sortedCancelledSessions, comments]);

  useLayoutEffect(() => {
    if (!sortedSessions) return;
    const printIF = document.getElementById("sessions-print-iframe").contentWindow.document;
    let styles = "";
    for (let stylesheet of document.styleSheets) {
      try {
        if (stylesheet.cssRules) {
          for (let cssRule of stylesheet.cssRules) styles += cssRule.cssText;
        }
      } catch (e) {
        if (e.name !== "SecurityError") throw e; // Rethrow if it's not a security error
      }
    }
    styles = "<style>" + styles + "</style>";
    printIF.open();
    printIF.write(styles + "<div id='iframe-content'/>");
    printIF.close();
  }, [sortedSessions]);

  useEffect(() => {
    if (!topicToPrint) return;
    let filteredSessions = sortedSessions.slice();

    switch (topicToPrint) {
      case "Confirmed":
        filteredSessions = sortedSessions.filter((session) => session.state === "Confirmed");
        break;
      case "Technology and Technique":
        filteredSessions = sortedSessions.filter((session) => session.topic === "Technology and Technique" && session.state !== "Cancelled" && session.state !== "Draft");
        break;
      case "Customer and Planning":
        filteredSessions = sortedSessions.filter((session) => session.topic === "Customer and Planning" && session.state !== "Cancelled" && session.state !== "Draft");
        break;
      case "Intro's and Cases":
        filteredSessions = sortedSessions.filter((session) => session.topic === "Intro's and Cases" && session.state !== "Cancelled" && session.state !== "Draft");
        break;
      case "Process and Improvement":
        filteredSessions = sortedSessions.filter((session) => session.topic === "Process and Improvement" && session.state !== "Cancelled" && session.state !== "Draft");
        break;
      case "Team and Individual":
        filteredSessions = sortedSessions.filter((session) => session.topic === "Team and Individual" && session.state !== "Cancelled" && session.state !== "Draft");
        break;
      case "Other":
        filteredSessions = sortedSessions.filter((session) => session.topic === ("Other" || "other") && session.state !== "Cancelled" && session.state !== "Draft");
      case "All":
        // No filtering needed
        break;
      default:
        filteredSessions = sortedSessions.filter((session) => session.topic === topicToPrint && session.state !== "Cancelled" && session.state !== "Draft");
        break;
    }

    setSessionsToPrint(filteredSessions);
    setTopicToPrint("");
  }, [sortedSessions, topicToPrint]);

  useEffect(() => {
    if (sessionsToPrint.length === 0) return;
    const cardContainer = document.getElementById("sessions-print-iframe").contentWindow;
    cardContainer.focus();
    cardContainer.print();
    setSessionsToPrint([]);
  }, [sessionsToPrint]);

  const [currentSortCondition, setSortCondition] = useState(null);
  const [currentSortConditionForCancelled, setSortConditionForCancelled] = useState(null);

  // Save sorts to session storage on change
  useEffect(() => {
    if (!loadedSortFromStorage) return;
    sessionStorage.setItem("sort_sessions", JSON.stringify(currentSortCondition));
    sessionStorage.setItem("sort_cancelled_sessions", JSON.stringify(currentSortConditionForCancelled));
  }, [currentSortCondition, currentSortConditionForCancelled, loadedSortFromStorage]);

  // Load sorts from session storage
  useEffect(() => {
    if (!sessions || !sortedSessions || !sortedCancelledSessions || loadedSortFromStorage) return;
    const savedSortForSessions = sessionStorage.getItem("sort_sessions");
    const savedSortForCancelledSessions = sessionStorage.getItem("sort_cancelled_sessions");
    if (savedSortForSessions) {
      const parsedSortFromStorage = JSON.parse(savedSortForSessions);
      setSortCondition(parsedSortFromStorage);
      sort(sessions, parsedSortFromStorage.sortBy, parsedSortFromStorage.arrangement, setSortedSessions, setSortCondition);
    }
    if (savedSortForCancelledSessions) {
      const parsedSortFromStorage = JSON.parse(savedSortForCancelledSessions);
      setSortConditionForCancelled(parsedSortFromStorage);
      sortCancelledSessions(cancelledSessions, parsedSortFromStorage.sortBy, parsedSortFromStorage.arrangement, setSortedCancelledSessions, setSortConditionForCancelled);
    }
    setLoadedSortFromStorage(true);
  }, [sortedSessions, sortedCancelledSessions, sessions, loadedSortFromStorage, cancelledSessions]);

  useEffect(() => {
    stateReviewPromise.then((promisedState) => setStateReview(promisedState));
  }, [stateReviewPromise]);

  return (
    <Container
      fluid
      style={{ maxWidth: "75%" }}
    >
      <h1 className={"sessionListTitle"}>Sessions list</h1>
      <div className={"sessionListSubTitle"}>
        List contains <b>{sessions && sessions.filter((s) => s.state !== "Cancelled").length}</b> sessions
      </div>
      {sortedSessions && (
        <>
          <div className={"table-responsive-sm"}>
            {sortedSessions && sortedSessions.length > 0 && (
              <Table
                bordered
                className={"sessionTable"}
              >
                <thead>
                  <tr>
                    <th style={{ cursor: "default", width: "2%" }} />

                    <th onClick={() => sort(sessions, SortBy.Title, !currentSortCondition.arrangement, setSortedSessions, setSortCondition)}>Title {sortIcon(SortBy.Title, currentSortCondition)}</th>

                    <th onClick={() => sort(sessions, SortBy?.Presenters, !currentSortCondition.arrangement, setSortedSessions, setSortCondition)}>Presenter(s) {sortIcon(SortBy?.Presenters, currentSortCondition)}</th>
                    {stateReview && <th onClick={() => sort(sessions, SortBy.Reviews, !currentSortCondition.arrangement, setSortedSessions, setSortCondition)}>#Reviews {sortIcon(SortBy.Reviews, currentSortCondition)}</th>}

                    {/* Only admins can see vote count */}
                    {user && currentPresenter?.role === "admin" && (
                      <>
                        <th onClick={() => sort(sessions, SortBy.Votes, !currentSortCondition.arrangement, setSortedSessions, setSortCondition)}>#Votes {sortIcon(SortBy.Votes, currentSortCondition)}</th>
                      </>
                    )}

                    <th onClick={() => sort(sessions, SortBy.Topic, !currentSortCondition.arrangement, setSortedSessions, setSortCondition)}>Topic {sortIcon(SortBy.Topic, currentSortCondition)}</th>

                    {/* Only admins can see created & modified date/times */}
                    {user && currentPresenter?.role === "admin" && (
                      <>
                        <th onClick={() => sort(sessions, SortBy.Created, !currentSortCondition.arrangement, setSortedSessions, setSortCondition)}>Created {sortIcon(SortBy.Created, currentSortCondition)}</th>

                        <th onClick={() => sort(sessions, SortBy.Modified, !currentSortCondition.arrangement, setSortedSessions, setSortCondition)}>Modified {sortIcon(SortBy.Modified, currentSortCondition)}</th>
                        <th onClick={() => sort(sessions, SortBy.state, !currentSortCondition.arrangement, setSortedSessions, setSortCondition)}>State {sortIcon(SortBy.state, currentSortCondition)}</th>
                      </>
                    )}
                  </tr>
                  {sortedSessions.map((session, index) => (
                    <SessionRowFromList
                      session={session}
                      index={index}
                      numberOfReviews={reviews.filter((review) => review.sessionDocID === session.id).length}
                      key={session.id}
                      user={user}
                      currentPresenter={currentPresenter}
                    />
                  ))}
                </thead>
              </Table>
            )}
          </div>

          {currentPresenter?.role === "admin" && (
            <div style={{ display: "flex", gap: "10px" }}>
              <Dropdown onSelect={(e) => setTopicToPrint(e)}>
                <Dropdown.Toggle className={"button-standard"}>Print sessions</Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item eventKey={"Technology and Technique"}>Technology and Technique</Dropdown.Item>
                  <Dropdown.Item eventKey={"Process and Improvement"}>Process and Improvement</Dropdown.Item>
                  <Dropdown.Item eventKey={"Customer and Planning"}>Customer and Planning</Dropdown.Item>
                  <Dropdown.Item eventKey={"Team and Individual"}>Team and Individual</Dropdown.Item>
                  <Dropdown.Item eventKey={"Intro's and Cases"}>Intro's and Cases</Dropdown.Item>
                  <Dropdown.Item eventKey={"Other"}>Other</Dropdown.Item>
                  <Dropdown.Item eventKey={"All"}>All</Dropdown.Item>
                  <Dropdown.Item eventKey={"Confirmed"}>Confirmed</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>

              <Dropdown>
                <Dropdown.Toggle className={"button-standard"}>Downloads & exports</Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() => {
                      dbApi.downloadConfirmedSessions();
                    }}
                  >
                    Download confirmed Sessions
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      dbApi.downloadDraftSessions();
                    }}
                  >
                    Download draft Sessions
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      handleExportClick();
                    }}
                  >
                    Export all sessions
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          )}

          <iframe
            title={"sessions-print-iframe"}
            id={"sessions-print-iframe"}
            style={{ display: "none" }}
          >
            {document.getElementById("sessions-print-iframe") && ReactDOM.createPortal(<SessionsPrintPage sessions={sessionsToPrint} />, document.getElementById("sessions-print-iframe").contentWindow.document.getElementById("iframe-content"))}
          </iframe>
          <h1
            style={{ marginTop: "1em" }}
            className={"sessionListTitle"}
          >
            Cancelled sessions
          </h1>
          <div className={"sessionListSubTitle"}>
            List contains <b>{sessions.filter((session) => session.state === "Cancelled").length}</b> sessions
          </div>

          <div className={"table-responsive-sm"}>
            <Table
              bordered
              className={"sessionTable"}
            >
              <thead>
                <tr>
                  <th style={{ cursor: "default", width: "2%" }} />

                  <th onClick={() => sortCancelledSessions(cancelledSessions, SortBy.Title, !currentSortConditionForCancelled.arrangement, setSortedCancelledSessions, setSortConditionForCancelled)}>Title {sortIcon(SortBy.Title, currentSortConditionForCancelled)}</th>

                  <th onClick={() => sortCancelledSessions(cancelledSessions, SortBy?.Presenters, !currentSortConditionForCancelled.arrangement, setSortedCancelledSessions, setSortConditionForCancelled)}>Presenter(s) {sortIcon(SortBy?.Presenters, currentSortConditionForCancelled)}</th>
                  {stateReview && <th onClick={() => sortCancelledSessions(sessions, SortBy.Reviews, !currentSortConditionForCancelled.arrangement, setSortedCancelledSessions, setSortConditionForCancelled)}>#Reviews {sortIcon(SortBy.Reviews, currentSortCondition)}</th>}

                  {/* Only admins can see vote count */}
                  {user && currentPresenter?.role === "admin" && (
                    <>
                      <th onClick={() => sortCancelledSessions(cancelledSessions, SortBy.Votes, !currentSortConditionForCancelled.arrangement, setSortedCancelledSessions, setSortConditionForCancelled)}>#Votes {sortIcon(SortBy.Votes, currentSortConditionForCancelled)}</th>
                    </>
                  )}

                  <th onClick={() => sortCancelledSessions(cancelledSessions, SortBy.Topic, !currentSortConditionForCancelled.arrangement, setSortedCancelledSessions, setSortConditionForCancelled)}>Topic {sortIcon(SortBy.Topic, currentSortConditionForCancelled)}</th>

                  {/* Only admins can see created & modified date/times */}
                  {user && currentPresenter?.role === "admin" && (
                    <>
                      <th onClick={() => sortCancelledSessions(cancelledSessions, SortBy.Created, !currentSortConditionForCancelled.arrangement, setSortedCancelledSessions, setSortConditionForCancelled)}>Created {sortIcon(SortBy.Created, currentSortConditionForCancelled)}</th>

                      <th onClick={() => sortCancelledSessions(cancelledSessions, SortBy.Modified, !currentSortConditionForCancelled.arrangement, setSortedCancelledSessions, setSortConditionForCancelled)}>Modified {sortIcon(SortBy.Modified, currentSortConditionForCancelled)}</th>
                      <th onClick={() => sortCancelledSessions(cancelledSessions, SortBy.state, !currentSortConditionForCancelled.arrangement, setSortedCancelledSessions, setSortConditionForCancelled)}>State {sortIcon(SortBy.state, currentSortConditionForCancelled)}</th>
                    </>
                  )}
                </tr>
                {sortedCancelledSessions.map((session, index) => (
                  <SessionRowFromList
                    session={session}
                    index={index}
                    numberOfReviews={reviews.filter((review) => review.sessionDocID === session.id).length}
                    key={session.id}
                    user={user}
                    currentPresenter={currentPresenter}
                  />
                ))}
              </thead>
            </Table>
          </div>
        </>
      )}
    </Container>
  );
}

function sortIcon(sortBy, sortCondition) {
  return sortCondition.sortBy === sortBy ? sortCondition.arrangement === Arrangement.Ascending ? <>&#8595;</> : <>&#8593;</> : "";
}

function sort(sessions, by, arrangement, setSortedSessions, setSortCondition) {
  let filteredSessions = sessions.filter((session) => session.state !== "Cancelled");
  filteredSessions = filteredSessions.sort((a, b) => {
    let comparison;
    switch (by) {
      case SortBy.Title:
        if (a.title == null) {
          comparison = 1;
          break;
        }
        if (b.title == null) {
          comparison = -1;
          break;
        }
        comparison = a.title.localeCompare(b.title);
        break;
      case SortBy?.Presenters:
        if (a?.presenters[0] == null || a?.presenters[0].firstName == null) {
          comparison = 1;
          break;
        }
        if (b?.presenters[0] == null || b?.presenters[0].firstName == null) {
          comparison = -1;
          break;
        }
        comparison = a?.presenters[0].firstName.localeCompare(b?.presenters[0].firstName);
        break;
      case SortBy.Reviews:
        if (a.totalReviews == null) {
          comparison = 1;
          break;
        }
        if (b.totalReviews == null) {
          comparison = -1;
          break;
        }
        comparison = a.totalReviews - b.totalReviews;
        break;
      case SortBy.Votes:
        if (a.totalVotes == null) {
          comparison = 1;
          break;
        }
        if (b.totalVotes == null) {
          comparison = -1;
          break;
        }
        comparison = a.totalVotes - b.totalVotes;
        break;
      case SortBy.Topic:
        if (!("topic" in a) || !a.topic) {
          comparison = 1;
          break;
        }
        if (!("topic" in b) || !b.topic) {
          comparison = -1;
          break;
        }
        comparison = a.topic.localeCompare(b.topic);
        break;
      case SortBy.Created:
        comparison = a.created.seconds - b.created.seconds;
        break;
      case SortBy.Modified:
        comparison = a.modified.seconds - b.modified.seconds;
        break;
      case SortBy.state:
        if (a.state === b.state) {
          comparison = 0;
        } else if (a.state === "Confirmed") {
          comparison = -1;
        } else if (b.state === "Confirmed") {
          comparison = 1;
        } else {
          comparison = a.state.localeCompare(b.state);
        }
        break;
      default:
        comparison = 0;
        break;
    }
    return arrangement === Arrangement.Ascending ? comparison : -comparison;
  });
  setSortedSessions(filteredSessions);
  setSortCondition({
    sortBy: by,
    arrangement: arrangement,
  });
}

function sortCancelledSessions(sessions, by, arrangement, setSortedCancelledSessions, setSortConditionForCancelled) {
  let cancelledSessions = sessions.filter((session) => session.state === "Cancelled");
  cancelledSessions = cancelledSessions.sort((a, b) => {
    let comparison;
    switch (by) {
      case SortBy.Title:
        if (a.title == null) {
          comparison = 1;
          break;
        }
        if (b.title == null) {
          comparison = -1;
          break;
        }
        comparison = a.title.localeCompare(b.title);
        break;
      case SortBy?.Presenters:
        if (a?.presenters[0] == null || a?.presenters[0].firstName == null) {
          comparison = 1;
          break;
        }
        if (b?.presenters[0] == null || b?.presenters[0].firstName == null) {
          comparison = -1;
          break;
        }
        comparison = a?.presenters[0].firstName.localeCompare(b?.presenters[0].firstName);
        break;
      case SortBy.Reviews:
        if (a.totalReviews == null) {
          comparison = 1;
          break;
        }
        if (b.totalReviews == null) {
          comparison = -1;
          break;
        }
        comparison = a.totalReviews - b.totalReviews;
        break;
      case SortBy.Votes:
        if (a.totalVotes == null) {
          comparison = 1;
          break;
        }
        if (b.totalVotes == null) {
          comparison = -1;
          break;
        }
        comparison = a.totalVotes - b.totalVotes;
        break;
      case SortBy.Topic:
        if (!("topic" in a) || !a.topic) {
          comparison = 1;
          break;
        }
        if (!("topic" in b) || !b.topic) {
          comparison = -1;
          break;
        }
        comparison = a.topic.localeCompare(b.topic);
        break;
      case SortBy.Created:
        comparison = a.created.seconds - b.created.seconds;
        break;
      case SortBy.Modified:
        comparison = a.modified.seconds - b.modified.seconds;
        break;
      case SortBy.state:
        if (a.state === b.state) {
          comparison = 0;
        } else if (a.state === "Confirmed") {
          comparison = -1;
        } else if (b.state === "Confirmed") {
          comparison = 1;
        } else {
          comparison = a.state.localeCompare(b.state);
        }
        break;
      default:
        comparison = 0;
        break;
    }
    return arrangement === Arrangement.Ascending ? comparison : -comparison;
  });

  setSortedCancelledSessions(cancelledSessions);
  setSortConditionForCancelled({
    sortBy: by,
    arrangement: arrangement,
  });
}
